.slider{
    overflow: hidden;
}

.slider__arrow{
    background: var(--color-default);
    color: #fff;
    position: absolute;
    top: 50%;
    padding: 10px 17px 10px 15px;
    font-size: 20px;
    line-height: 0;
    z-index: 5;
}

.slider__arrow.slick-prev{
    left: -16px;
    transform: skew(-20deg) translateY(-50%);
    padding: 10px 15px 10px 17px;

}
.slider__arrow.slick-next{
    right: 0;
    transform: skew(-20deg) translateY(-50%);
}

.slider__arrow .icon{
    vertical-align: middle;
    transform: skew(20deg);
    position: relative;
    top: 1px;
}

.slider__arrow.slick-prev .icon{
    top: -1px;
}

.slider__arrow.slick-prev .icon:before{
    transform: rotate(180deg);
    display: block;
}

.image-slider .slider__arrow{
    background: #fff;
    color: var(--color-primary);
}

.header-slider .slider__arrow{
    padding: 8px 18px 8px 20px;
    border: 1px solid #848484;
}

.header-slider .slider__arrow .icon{
    font-size: 24px;
}

@media screen and (min-width: 768px){
    .slider__arrow{
        transition: all 200ms ease;
    }

    .slider__arrow.slick-prev:hover{
        left: -18px;
    }

    .slider__arrow.slick-next:hover{
        right: -2px;
    }
}

@media screen and (max-width: 767px){
    .news-slider .slider__arrow{
        transform: skew(-15deg) translateY(-50%);
        padding: 10px 7px 10px 17px;
        font-size: 16px;
    }

    .news-slider .slider__arrow.slick-next{
        padding: 10px 10px 10px 8px;
    }
}


/* Slick dots */

.slick-dots{
    list-style: none;
    padding-left: 0;
    text-align: center;
    margin-top: 15px;
}

.slick-dots>li{
    display: inline-block;
    margin: 0 7px;
    width: 15px;
    height: 15px;
}

.slick-dots>li button{
    background: none;
    box-shadow: none;
    border: 1px solid var(--color-grey-border);
    color: transparent;
    line-height: 0;
    background: #fff;
    width: 15px;
    height: 15px;
    padding: 0;
    border-radius: 50%;
}

.slick-dots>li.slick-active button{
    background: var(--color-primary);
    width: 18px;
    height: 18px;
}

a.markerinfolink{
    display: block;
    margin-top: 15px;
    text-decoration: underline;
}