.image-slide{
    position: relative;
    color: #fff;
    overflow: hidden;
}

@media screen and (min-width: 992px){
    .image-slide:before {
        content: '';
        width: 0;
        height: 0;
        border-style: solid;
        border-width: 40px 1600px 0 0;
        border-color: #f6f6f6 transparent transparent transparent;
        top: 0;
        position: absolute;
        left: 0;
        right: 0;
        z-index: 5;
    }

    .image-slide:after {
        content: '';
        width: 0;
        height: 0;
        border-style: solid;
        border-width: 0 0 40px 1600px;
        border-color: transparent transparent #f6f6f6 transparent;
        bottom: 0;
        position: absolute;
        left: 0;
        right: 0;
        z-index: 6;
    }

}

.image-slide .image-slide__body:before{
    content: '';
    position: absolute;
    top:0;
    bottom:0;
    left:0;
    right: 0;
    z-index: 0;
}

.image-slide.image-slide--black .image-slide__body:before{
    background: rgba(0,0,0,0.6);
}

.image-slide.image-slide--red .image-slide__body:before{
    background: rgba(201,16,25,0.5);
}

.image-slide.image-slide--red .btn-switch-color{
    background: #fff;
    color: var(--color-primary);
}

.image-slide.image-slide--red .btn-switch-color:hover{
    background: var(--color-primary);
    color: #fff;
}

.image-slide__bg-img img{
    -webkit-filter: grayscale(100%);
    filter: grayscale(100%);
}

.image-slide__body{
    position: absolute;
    left:0;
    right: 0;
    top:0;
    bottom:0;
    z-index: 2;
}

.image-slide__text{
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
}

.image-slide__text .btn{
    margin-top: 50px;
}

.image-slide .quotation{
    font-size: 35px;
    line-height: 38px;
    position: relative;
}

.image-slide .quotation:before{
    content: '“';
    position: absolute;
    left: -42px;
    top: 17px;
    font-size: 65px;
}

.image-slide .quotation:after{
    content: '”';
    position: absolute;
    bottom: -10px;
    font-size: 65px;
    margin-left: 10px;
}

.image-slide.image-slide--black .quotation:before,
.image-slide.image-slide--black .quotation:after{
    color: var(--color-primary);
}

.image-slide__testimonial{
    text-align: right;
    height: 100%;
    position: relative;
}

.image-slide__testimonial img{
    display: inline-block;
    position: absolute;
    right:0;
    bottom:0;
    max-height: 476px;
    height: 100%;
    width: auto;
}

@media screen and (min-width: 992px){
    .image-slide .quotation{
        font-size: 40px;
        line-height: 43px;
    }

}

@media screen and (min-width: 768px){
    .image-slide .quotation{
        font-size: 30px;
        line-height: 35px;
    }

    .image-slide__text .btn{
        margin-top: 30px;
    }
}

@media screen and (max-width: 767px){
    .image-slide__text{
        text-align: center;
        margin-top: 25px;
    }

    .image-slide .quotation:before {
        left: 50%;
        transform: translateX(-50%);
        top: -30px;
    }

    .image-slide .quotation:after {
        bottom: -53px;
        font-size: 65px;
        margin-left: 0;
        left: 50%;
        transform: translateX(-50%);
    }
}