.eps-tl__teaser {
    padding: 25px 15px 25px 41px;
    background: #efefef;
}

@media screen and (max-width: 768px) {
    .eps-tl__teaser {
        padding: 15px;
    }
}

.eps-tl__teaser:nth-of-type(2n) {
    background: #fff;
}

.eps-tl__teaser__subline {
    color: #999;
}

.eps-tl__teaser__content{
    position: relative;
    /*top: 50%;*/
    /*transform: translateY(-50%);*/
}

.eps-tl__teaser__img {
    position: relative;
}

.eps-tl__teaser__headline.has-time-marker:before {
    left: -110px;
}

@media screen and (max-width: 768px) {
    .eps-tl__teaser__headline.has-time-marker:before {
        left: -39px;
    }
}

.isExclusive {
    color: var(--color-primary);
    position: relative;
}

.isExclusive:before {
    position: absolute;
    top: 0;
    left: -90px;
    content: "\E018";
    font-family: 'iconfont';
    font-size: 25px;
}

@media screen and (max-width: 768px) {
    .isExclusive:before {
        font-size: 15px;
        left: -33px;
    }
}

.isExclusive + .has-time-marker:before {
    content: none;
}

.eps-tl__teaser__img-hotspot-wrapper {
    position: relative;
}