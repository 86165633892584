.loading-container {
    position: relative;
}

.loading-container .spinner {
    display: none;
}

.loading-container.is-loading .spinner {
    display: block;
}

.spinner-container {
    position: absolute;
    text-align: center;
    width: 100%;
    z-index: 1000;
}


.spinner,
.spinner:after {
    border-radius: 50%;
    width: 10em;
    height: 10em;
}
.spinner {
    margin: 60px auto;
    font-size: 10px;
    position: relative;
    text-indent: -9999em;
    border-top: 1.1em solid rgba(201,16,25, 0.2);
    border-right: 1.1em solid rgba(201,16,25, 0.2);
    border-bottom: 1.1em solid rgba(201,16,25, 0.2);
    border-left: 1.1em solid #c91019;
    transform: translateZ(0);
    animation: load8 1.1s infinite linear;
}
@keyframes load8 {
    0% {
        transform: rotate(0deg);
    }
    100% {
        transform: rotate(360deg);
    }
}
