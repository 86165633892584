.wysiwyg h3{
    margin-bottom: 10px;
    text-transform: uppercase;
}

.wysiwyg ul{
   list-style: none;
    padding: 0;
}

.wysiwyg ul>li{
    padding-left: 20px;
    position: relative;
    margin-bottom: 7px;
    line-height: 22px;
}

.wysiwyg ul>li:before{
    content: '';
    width: 6px;
    height: 6px;
    background: var(--color-primary);
    position: absolute;
    left:0;
    top:8px;
}

.wysiwyg a {
    color: var(--color-primary);
}