.content-teaser__img{
    position: relative;
    background: rgb(239,239,239);
    background: linear-gradient(to bottom,#efefef 0,#efefef 68%,#dadada 72%,#e8e8e8 84%,#e8e8e8 100%);
}
.content-teaser__category{
    position: absolute;
    left:0;
    bottom: 0;
    padding: 5px 20px;
}

.content-teaser--download .content-teaser__body{
    padding: 5px 25px 25px;
    border-top:1px solid #dad7d7;
}

.content-teaser--download{
    border: 1px solid #dad7d7;
}

.content-teaser .content-teaser__img img{
    transition: transform 250ms ease;
    backface-visibility: hidden;
}

.content-teaser:hover .content-teaser__img img{
    transform: scale(1.03) translateZ(0);
}