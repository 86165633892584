
.hotspot{
    background: var(--color-default);
    width: 26px;
    height: 26px;
    border-radius: 50%;
    border: 1px solid #fff;
    position: absolute;
    top:100px;
    left:200px;
    z-index: 3;
}
.hotspot__inner{
    width: 10px;
    height: 10px;
    background: #ffe300;
    border-radius: 50%;
    position: absolute;
    top: 50%;
    left:50%;
    transform: translateX(-50%) translateY(-50%);
}

.popover{
    background: var(--color-default);
    color: #fff;
    font-size: 12px;
    border-radius: 0;
}


.circle-pulse{
    width: 26px;
    height: 26px;
    border-radius: 50%;
    border: 1px solid #fff;
    z-index: 1;
    display: block;
    position: relative;
    top: -1px;
    left: -1px;
    opacity: 0;
    transition: opacity 100ms ease;
}

.hotspot .circle-pulse{
    opacity: 1;
}

.pulse-animation{
    animation-name: pulse_animation;
    animation-duration: 1100ms;
    animation-iteration-count: infinite;
    animation-timing-function: linear;
}

@keyframes pulse_animation {
    0% { transform: scale(1); opacity: 0;}
    50% { transform: scale(1.2); opacity: 1;}
    75% { transform: scale(1.4); }
    100% { transform: scale(1.6); opacity: 0; }
}

