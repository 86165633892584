.dealer-teaser {
    background-color: #fff;
    border: 1px solid var(--color-grey-border);
    margin-bottom: 20px;
}

.dealer-teaser__body  {
    padding: 20px;
}

@media screen and (min-width: 768px) {
    .dealer-teaser__body  {
        border-top: 1px solid var(--color-grey-border);
        padding: 30px 30px 20px;
    }

}

.dealer-teaser__name {
    font-size: 20px;
    text-transform: uppercase;
    font-weight: bold;
    padding-bottom: 5px;
    line-height: 1.2;
}

.dealer-teaser__img {
    position: absolute;
    max-width: 100%;
    max-height: 100%;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    margin: auto;
    padding: 30px;
}

.contact-list {
    margin-top: 15px;
    margin-bottom: 15px;
}

.contact-list__icon {
    color: var(--color-primary);
    min-width: 19px;
    vertical-align: -.2em;
}
.contact-list__icon.icon-phone {
    font-size: 19px;
}

@media screen and (max-width: 767px) {
    .contact-list__icon,
    .contact-list__icon.icon-phone {
        font-size: 40px;
    }

    .contact-list>li {
        display: inline-block;
        padding-left: 10px;
        padding-right: 10px;
    }

    .contact-list>li:first-child {
        padding-left: 0;
    }

}

/* dealer teaser as map info*/

.map-info__wrapper .dealer-teaser {
    position: relative;
    padding-top: 90px;
    height: 100%;
    background-color: #fff;
    border: 1px solid var(--color-grey-border);
}

@media screen and (max-width: 767px) {
    .map-info__wrapper .dealer-teaser {
        padding-top: 0;
    }
}

.map-info__close {
    position: absolute;
    top: 30px;
    right: 30px;
    width: 24px;
    height: 24px;
    font-size: 25px;
    display: none;
}

.map-info__wrapper .map-info__close {
    display: block;
}

.map-info__wrapper .dealer-teaser__body  {
    border: none;
}

.map-info__wrapper .dealer-teaser__name {
    font-size: 20px;
    text-transform: uppercase;
    font-weight: bold;
    padding-bottom: 5px;
}

.map-info__wrapper .js-show-on-map {
    display: none;
}

