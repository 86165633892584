.content-block.content-block--newsroom{
    margin-bottom: 0;
    padding-bottom: 90px;
    background-image: url('/static/build/img/pattern/tree-pattern.jpg');
    background-repeat: no-repeat;
    background-position: bottom center;
}

@media screen and (min-width: 768px) {
    .content-block.content-block--newsroom {
        padding-bottom: 180px;
    }
}