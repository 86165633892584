/*list-arrow*/
.list-arrow {
    list-style-type: none;
    padding-left: 0;
    margin-bottom: 0;
}
.list-arrow>li {
    padding-left: 1.1em;
    padding-bottom: 3px;
    text-indent: -.5em;
}
.list-arrow>li:before {
    content: var(--icon-chevron);
    font-size: 16px;
    margin-right: .1em;
    vertical-align: -.2em;

    display: inline-block;
    font-family: "iconfont";
    speak: none;
    font-style: normal;
    font-weight: normal;
    line-height: 1;
    text-rendering: auto;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    transform: translate(0, 0);
}
.list-arrow > li > a:hover,
.list-arrow > li.active > a {
    text-decoration: underline;
    /*color: var(--color-primary);*/
}