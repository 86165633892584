.news-slider{
    margin-top: 50px;
}
.news-slider.container{
    max-width: 1400px;
    padding: 0;
}

.news-slider__body{
    background: #fff;
    border: 1px solid var(--color-grey-border);
    height: 100%;
    padding: 15px 52px 20px 35px;
}

@media screen and (min-width: 768px){
    .news-slider__body{
        padding: 35px 52px 35px 35px;
    }
}

@media screen and (min-width: 768px) and (max-width: 1199px){
    .news-slider__desc{
        max-height: 145px;
        overflow: hidden;
    }
}

