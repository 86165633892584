.staff-teaser{
    background: #fcfcfd;
}

.staff-teaser__hl{
    font-size: 25px;
    line-height: 1.14286;
}

.staff-teaser__body{
    padding: 20px 20px;
    height: 100%;
    border: 1px solid var(--color-grey-border);
}

.staff-teaser__desc{
    color: #999;
    font-size: 20px;
    font-family: var(--font-trade-gothic-regular);
    margin-top: 5px;
}

.staff-teaser__text{
    line-height: 21px;
    margin-top: 10px;
}

.staff-teaser__img{
    position: relative;
}

.staff-teaser__img-person{
    position: absolute;
    bottom: 0;
    z-index: 2;
}

.staff-teaser__img:before{
    content: '';
    position: absolute;
    background: rgba(0,0,0,.6);
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    z-index: 1;
}

.staff-teaser__img-bg img{
    filter: grayscale(100%);
}

@media screen and (min-width: 1300px){
    .staff-teaser__body{
        padding: 32px 35px;
    }

    .staff-teaser__hl{
        font-size: 35px;
    }

    .staff-teaser__text{
        margin-top: 20px;
    }
}

@media screen and (min-width: 768px) and (max-width: 1099px){
    .staff-teaser__body{
        padding: 15px;
    }

    .staff-teaser__hl{
        font-size: 22px;
    }

    .staff-teaser__text{
        font-size: 15px;
    }
}

@media screen and (max-width: 767px){
    .staff-teaser{
        margin-bottom: 20px;
    }
}

/* staff-teaser--wide */
.staff-teaser.staff-teaser--wide .staff-teaser__img:before {
    display: none;
}

.staff-teaser.staff-teaser--wide {
    border: 1px solid var(--color-grey-border);
    border-top: none;
}

.staff-teaser.staff-teaser--wide .staff-teaser__body{
    border: none;
    border-left: 1px solid var(--color-grey-border);
}

@media screen and (max-width: 767px) {

    .staff-teaser.staff-teaser--wide .staff-teaser__body{
        border: none;
        border-top: 1px solid var(--color-grey-border);
    }

    .staff-teaser.staff-teaser--wide {
        border-top: 1px solid var(--color-grey-border);
    }

}

li:first-child .staff-teaser.staff-teaser--wide {
    border-top: 1px solid var(--color-grey-border);
}

@media screen and (min-width: 767px) {
    .staff-teaser.staff-teaser--wide.staff-teaser--inverted .staff-teaser__body {
        border: none;
        border-right: 1px solid var(--color-grey-border);
        margin-right: -1px;
        /*margin-top: -1px;*/

    }
}

