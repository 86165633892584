.teaser-more-arrow .icon{
    font-size: 23px;
    line-height: 0;
    position: relative;
    top: 6px;
    margin-left: 10px;
}

.teaser__video-badge{
    background: var(--color-primary);
    color: #fff;
    height: 60px;
    width: 60px;
    border-radius: 50%;
    border: 2px solid #fff;
    font-size: 31px;
}

.teaser__video-badge .icon{
    left: 54%;
}