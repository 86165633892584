.epsolutions-timeline {
    position: relative;
    font-family: var(--font-trade-gothic-regular);
    padding-bottom: 45px;
    padding-left: 125px;
}

.panel-group.epsolutions-timeline {
    border: none;
}

.epsolutions-timeline:before {
    top: 33px;
    bottom: 0;
    position: absolute;
    content: " ";
    width: 3px;
    background-color: var(--color-default);
    left: 5px;
}

.epsolutions-timeline:after {
    top: 33px;
    position: absolute;
    content: " ";
    border-left: 3px solid var(--color-default);
    border-top: 3px solid var(--color-default);
    transform: rotate(45deg);
    left: -4px;
    height: 21px;
    width: 21px;
}

@media screen and (max-width: 768px) {
    .epsolutions-timeline {
        padding-left: 25px;

    }

    .epsolutions-timeline:before {
        left: 5px;
        top: 10px;
        width: 2px;
    }

    .epsolutions-timeline:after {
        height: 10px;
        width: 10px;
        left: 1px;
        top: 10px;
        border-left-width: 2px;
        border-top-width: 2px;
    }
}

/* ------------- TIMELINE ITEM / PANEL ---------- */

.epsolutions-timeline .panel {
    border: none;
    background: transparent;
    box-shadow: none;
}
.epsolutions-timeline .panel + .panel {
    border: none;
    margin-top: 45px;
}

.epsolutions-timeline .panel-title a {
    padding: 0;
    text-transform: none;
}

.epsolutions-timeline .panel-body {
    padding: 55px 26px 62px 85px;
    background: #fff;
    position: relative;
}



.epsolutions-timeline .detail-timeline {
    top: 90px;
    bottom: 60px;
    left: 30px;
    position: absolute;
    content: "";
    width: 3px;
    background-color: var(--color-default);
    display: block;
}

@media screen and (max-width: 768px) {
    .epsolutions-timeline .panel-body {
        padding: 10px;
    }

    .epsolutions-timeline .detail-timeline {
        top: 45px;
        left: 0px;
        bottom: 0;
        width: 2px;
    }
}

.eps-tl__toggle {
    font-size: 40px;
    color: var(--color-primary);
    position: absolute;
    right: 31px;
    bottom: 0;
}

.eps-tl__date {
    padding-left: 10px;
    position: absolute;
    left: -120px;
    bottom: 50%;
    transform: translateY(-50%);
    color: var(--color-primary);
    transition: bottom 300ms;
}


.collapse.in + .eps-tl__date {
    top: auto;
    bottom: 30px;
    transform: translateY(0);
}

.eps-tl__date:after {
    content: '';
    position: absolute;
    height: 2px;
    background: var(--color-default);
    left: 0;
    right: 0;
    top: 25px;
}

@media screen and (max-width: 768px) {
    .eps-tl__date {
        left: -18px;
        bottom: -28px;
        transform: none;
    }

    .collapse.in + .eps-tl__date {
        bottom: -28px;
    }

    .eps-tl__date:after {
        top: 20px;
    }
}


.eps-tl-item {
    position: relative;
}

.eps-tl__bg-img {
    position: relative;
}

.eps-tl__body {
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    z-index: 1;
    color: #fff;
}

.eps-tl-item__image:after {
    content: '';
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    z-index: 1;
    background: rgba(0,0,0,0.35);
}

/*.eps-tl__subtitle {*/
/*color: #999;*/
/*font-size: 20px;*/
/*}*/

.eps-tl__subline {
    font-size: 25px;
    position: absolute;
    left: 50%;
    bottom: 0;
    transform: translateX(-50%) translateY(-50%);
}

@media screen and (max-width: 768px) {
    .eps-tl__subline {
        font-size: inherit;
        position: static;
        transform: none;
    }
}

@media screen and (max-width: 1200px) {
    .eps-tl__subline {
       bottom: -10%;
    }
}

.eps-tl__subline .icon {
    color: var(--color-primary);
    transform: rotate(270deg);
}

.collapsed .eps-tl__subline .icon {
    color: #fff;
    transform: rotate(90deg);
}



.has-time-marker {
    position: relative;
}

.has-time-marker:before {
    content: '';
    position: absolute;
    left: 0;
    top: 10px;
    width: 30px;
    height: 2px;
    background: var(--color-default);
    display: block;
}

@media screen and (max-width: 768px) {
    .has-time-marker:before {
        width: 15px;
    }
}

/* --------- SERIE --------- */

.eps-tl__serie {
    position: relative;
    margin-top: 25px;
}

/*.eps-tl__serie:before {*/
    /*content: '';*/
    /*position: absolute;*/
    /*top: 10px;*/
    /*bottom: -95px;*/
    /*left: -70px;*/
    /*width: 3px;*/
    /*background: var(--color-default);*/
/*}*/

/*.eps-tl__serie:last-of-type:before {*/
    /*bottom: 0;*/
/*}*/

.eps-tl__serie + .eps-tl__serie,
.eps-tl__serie + .eps-tl__info,
.eps-tl__info + .eps-tl__serie {
    margin-top: 85px;
}

@media screen and (max-width: 768px) {
    .eps-tl__serie + .eps-tl__serie,
    .eps-tl__serie + .eps-tl__info,
    .eps-tl__info + .eps-tl__serie {
        margin-top: 35px;
    }
}

.eps-tl__serie h3,
.eps-tl__info h3 {
    margin-bottom: 25px;
}

.eps-tl__serie .has-time-marker:before {
    left: -67px;
}

.eps-tl__info h3.has-time-marker:before {
    left: -67px;
}

@media screen and (max-width: 768px) {
    .eps-tl__serie .has-time-marker:before {
        left: -23px;
    }
}

.eps-tl__serie:last-of-type:after {
    content: '';
    position: absolute;
    left: -67px;
    bottom: 0;
    width: 30px;
    height: 2px;
    background: var(--color-default);
    display: block;
}

.eps-tl__serie + .eps-tl__teaser {
    margin-top: 25px;
}