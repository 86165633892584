@media screen and (max-width: 767px){

    html.has-overflow-hidden, .has-overflow-hidden body {
        overflow: hidden;
        position: fixed;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
    }

    .navbar-nav{
        margin: 0;
    }

    .navbar-main {
        top: 0;
        left: 0;
        width: 100%;
        min-height: 70px;
        border-bottom: 1px solid #dad7d7;
        margin-bottom: 0;
    }

    .navbar-brand{
        height: 38px;
        float: right;
        padding: 16px 20px;
    }

    .navbar-brand>img{
        height: 38px;
    }

    .mobile-nav {
        display: block;
        position: fixed;
        top: 70px;
        right: 0;
        left: 0;
        bottom: 0;
        width: 100%;
        padding-top: 0;
        transform: translateX(-100%);
        transition: transform 200ms ease-in-out;
        z-index: 99;
        background: var(--color-grey);
    }

    .mobile-nav__content {
        position: absolute;
        left: 0;
        right: 0;
        top: 0;
        bottom: 0;
        overflow-x: hidden;
        overflow-y: scroll;
    }

    .mobile-nav.is-open {
        transform: translateX(0);
    }

    .navbar-toggle .icon-bar{
        transition: transform 250ms ease-in-out,opacity 250ms ease-in-out;
    }

    .is-open .icon-bar:nth-child(2) {
        opacity: 0;
    }

    .is-open .icon-bar:nth-child(3) {
        transform: rotate(-45deg);
    }

    .is-open .icon-bar:nth-child(4) {
        transform: rotate(45deg) translateX(-6px) translateY(-6px);
    }

    .mobile-nav .navbar-nav>li>a{
        padding: 16px 20px;
        border-bottom: 1px solid var(--color-grey-border);
    }

    .navbar-nav.navbar-nav--small>li>a {
        font-size: 14px;
        padding: 14px 20px;
    }

    .navbar-nav.navbar-nav--small>li:first-child>a {
        border-top: 1px solid #cecccc;
    }

    .mobile-nav .subnav-toggle,
    .mobile-nav .sub-subnav-toggle{
        position: absolute;
        right: 0;
        top: 0;
        padding: 15px 20px;
        font-size: 22px;
    }

    .mobile-nav .subnav-toggle:before,
    .mobile-nav .sub-subnav-toggle:before{
        transform: rotate(90deg);
        display: block;
    }

    .mobile-nav .has-subnav.is-open .subnav-toggle:before,
    .navbar-nav__subnav ul>li.has-subnav.is-open .sub-subnav-toggle:before{
        transform: rotate(-90deg);
        position: relative;
        left: 2px;
    }

    .mobile-nav .subnav-toggle:after {
        content: '';
        position: absolute;
        left: 0;
        top: 10px;
        bottom: 10px;
        width: 1px;
        background: #e4e4e4;
    }

    .navbar-nav__subnav{
        position: relative;
        border:none;
        border-bottom: 1px solid var(--color-grey-border);
        background: #efefef;

    }

    .navbar-nav>li.is-open .navbar-nav__subnav{
        display: block;
    }

    .navbar-subnav__subnav{
        display: none;
    }

    .navbar-nav__subnav ul>li.has-subnav.is-open .navbar-subnav__subnav{
        display: block;
    }

    .navbar-nav>li .navbar-nav__subnav a{
        padding: 12px 20px 12px 30px;
    }

    .navbar-nav>li .navbar-nav__subnav li>a .icon{
        padding: 10px 20px;
        right: 0;
    }

    .mobile-nav .navbar-nav>li>a:hover{
        background: transparent;
    }

    .navbar-nav>li .navbar-subnav__subnav li>a{
        padding: 12px 20px 12px 40px;

    }

    .navbar-lang_button{
        padding: 14px 20px;
        position: relative;
        padding-right: 25px;
        width: 100%;
        text-align: left;
        border-bottom: 1px solid #cecccc;
        border-top: 1px solid #cecccc;
    }

}
