@media screen and (min-width: 768px) {
    .map-info__wrapper {
        height: 100%;
        width: 32%;
        position: absolute;
        top: 0;
        right: 0;
    }
}


.map-info {
    position: relative;
    padding-top: 90px;
    height: 100%;
    background-color: #fff;
    border: 1px solid var(--color-grey-border);
}

@media screen and (max-width: 767px) {
    .map-info {
        padding-top: 0;
    }
}

.map-info__close {
    position: absolute;
    top: 30px;
    right: 30px;
    width: 24px;
    height: 24px;
    font-size: 25px;
}

.map-info__body  {
    padding: 20px;
}

.map-info__name {
    font-size: 20px;
    text-transform: uppercase;
    font-weight: bold;
    padding-bottom: 5px;
}