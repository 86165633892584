.dealer-map {
    position: relative;
}

.dealer-map__map {
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
}

.dealer-map__controls {
    position: absolute;
    top: 25px;
    right: 20px;
}

.js-has-active-marker .dealer-map__controls {
    right: 35%;
}

.dealer-map__control {
    position: relative;
    background: white;
    color: var(--color-primary);
    font-size: 19px;
    width: 50px;
    height: 40px;
    margin-right: 2px;
    transform: skew(-25deg);
}

.dealer-map__control__content {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%,-50%) skew(25deg);
    line-height: 1;
}

/*marker*/
.google-map__marker>.icon {
    font-size: 54px;
    padding-top: 1px;
    transition: color 250ms ease-in-out;
    color: var(--color-primary);
}

.google-map__marker .google-map__marker__circle {
    border-radius: 50%;
    background-color: white;
    width: 14px;
    height: 14px;
    position: absolute;
    left: 12px;
    top: 11px;
    transition: transform 120ms ease;
}

.google-map__marker-wrapper.is-active .google-map__marker__circle {
    /*width: 23px;*/
    /*height: 23px;*/
    /*left: 7px;*/
    /*top: 6px;*/
    transform: scale(1.7,1.7);
}