.accordion .panel-body {
    padding-top: 10px;
}

.panel-title>a>.icon-accordion{
    position: absolute;
    right: 52px;
    top: 50%;
    transform: translateY(-50%);
    display: block;
}

.panel-title>a>.icon-accordion:before{
    content: '';
    position: absolute;
    width: 22px;
    height: 2px;
    background: var(--color-primary);
}

.panel-title>a>.icon-accordion:after{
    content: '';
    position: absolute;
    height: 22px;
    width: 2px;
    background: var(--color-primary);
    left: 10px;
    top: -10px;
    opacity: 0;
    transition: opacity 250ms ease;
}

.accordion.accordion--highlight-selected .panel-title>a>.icon-accordion:before {
    background: white;
}

.accordion.accordion--highlight-selected .panel-title>a.collapsed>.icon-accordion:before{
    background: var(--color-primary);
}

.panel-title>a.collapsed>.icon-accordion:after{
    opacity: 1;
}

.panel-title a{
    text-transform: uppercase;
    color: var(--color-primary);
}

.panel-title a.collapsed{
    color: var(--color-default);
}

.accordion.accordion--highlight-selected .panel-title a{
    color: white;
    background: var(--color-primary);
    transition: background-color 240ms ease;
}
.accordion.accordion--highlight-selected .panel-title a.collapsed{
    color: var(--color-default);
    background: none;
}

.panel-title>a>.icon--accordion-arrow {
    vertical-align: -0.6em;
}

.panel-title>a>.icon--accordion-arrow:after {
    display: block;
    content: var(--icon-chevron);
    font-size: 30px;
    color: var(--color-primary);
    transition: transform 240ms ease;
    transform: rotate(-90deg);
}

.panel-title>a.collapsed>.icon--accordion-arrow:after {
    transform: rotate(90deg);
}


@media screen and (max-width: 767px){
    .panel-title.h3{
        font-size: 15px;
    }

    .panel-title a{
        padding: 15px;
        padding-right: 40px;
    }

    .panel-title>a>.icon-accordion{
        right: 33px;
    }

    .panel-title>a>.icon-accordion:before {
        width: 18px;
    }

    .panel-title>a>.icon-accordion:after {
        height: 18px;
        left: 8px;
        top: -8px;
    }

    .panel-body{
        padding: 0 15px 15px;
    }
}