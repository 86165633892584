.skewed-tabs>li>a {
    border-radius: 0;
    border: none;
    margin-right: 0;
    color: #cb1922;
    background: white;
    font-size: 21px;
    padding: 8px 15px;
}

.skewed-tabs>li.active>a,
.skewed-tabs>li.active>a:hover,
.skewed-tabs>li.active>a:focus {
    background: #cb1922;
    color: white;
    border: none;
}

.skewed-tabs>li:first-child {
    margin-left: -11px;
}

.skewed-tabs>li:last-child {
    margin-right: -7px;
}

.skewed-tabs {
    overflow: hidden;
    height: 40px;
    border: none;
}

.skewed-tabs>li>a {
    transform: skew(-25deg);
}

.skewed-tabs>li .skewed-tabs__content {
    transform: skew(25deg);
}

.skewed-tabs>li:first-child .skewed-tabs__content {
    padding-left: 10px;
}

.skewed-tabs>li:last-child .skewed-tabs__content {
    padding-right: 10px;
}
