.download-teaser{
    padding: 30px 0;
}
.download-teaser__body{
    padding: 25px;
}

@media screen and (min-width: 992px){
    .download-teaser__img{
        margin-left: 25px;
    }

    .download-teaser__body{
        padding: 25px 55px;
    }

}

@media screen and (min-width: 768px){
    .download-teaser__img{
        margin-left: 10px;
    }
}

.download-teaser__pdf-link .pdf-info{
    font-size: 12px;
    position: relative;
    top: -3px;
}

.download-teaser__pdf-link .icon{
    display: block;
    margin-right: 6px;
    font-size: 14px;
}
.download-teaser__pdf-link .icon.icon-arrow{
    font-size: 32px;
    margin-left: -5px;
    margin-right: 3px;
}

@media screen and (max-width: 767px){
    .download-teaser__img > img{
        margin: 0 auto;
        height: 200px;
    }

    .download-teaser__body {
        padding: 25px 35px 0 35px;
    }
}

.download-teaser__3D-preview {
    padding: 15px 0;
}

@media screen and (max-width: 768px) {
    .download-teaser__3D-preview {
        display: inline-block;
    }
}

.download-teaser__3D-preview__img {
    position: relative;
    transform: perspective(600px) rotateY(-25deg);
    box-shadow: 4px 5px 15px -5px rgba(0,0,0,.5);
}
