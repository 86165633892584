.content-teaser-dealer{
    padding: 20px;
    background-color: var(--color-primary);
    background-image: url(/static/debug/img/bg/bg-haendlersuche.png);
    background-size: cover;
    color: white;
}

.content-teaser-dealer__hl{
    font-size: 25px;
}
.content-teaser-dealer .find-dealer-form__select{
    width: 50%;
    float: left;
}

.content-teaser-dealer .find-dealer-form {
    margin-top: 20px;
    clear:both;
}

.content-teaser-dealer .find-dealer-form__select:after{
    font-size: 25px;
}

.content-teaser-dealer .find-dealer-form__btn{
    width: 40px;
    height: 40px;
    padding: 0;
    font-size: 28px;
    vertical-align: top;
    position: relative;
}


@media screen and (min-width: 1200px){
    .content-teaser-dealer{
        padding: 40px;
    }

    .content-teaser-dealer__hl{
        font-size: 35px;
    }
}