.image-slider {
    position: relative;
}


@media screen and (min-width: 992px){
    .image-slider:before {
        content: '';
        width: 0;
        height: 0;
        border-style: solid;
        border-width: 40px 1600px 0 0;
        border-color: #f6f6f6 transparent transparent;
        top: 0;
        position: absolute;
        left: 0;
        right: 0;
        z-index: 5;
    }

    .image-slider:after {
        content: '';
        width: 0;
        height: 0;
        border-style: solid;
        border-width: 0 0 40px 1600px;
        border-color: transparent transparent #f6f6f6;
        bottom: 0;
        position: absolute;
        left: 0;
        right: 0;
        z-index: 6;
    }

}

@media screen and (min-width: 768px){

    .image-slider__item {
        padding: 0 1.5px;
    }

    .image-slider .arrows-overlay{
        position: absolute;
        top: 0;
        bottom: 0;
        width: 10%;;
        z-index: 1;
    }

    .image-slider .arrows-overlay.arrow-prev{
        left:0;
        background: linear-gradient(to right, rgba(0,0,0,0.5) 0%,rgba(0,0,0,0) 100%);
        filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#66000000', endColorstr='#00000000',GradientType=1 );
    }

    .image-slider .arrows-overlay.arrow-next{
        background: linear-gradient(to right,  rgba(0,0,0,0) 0%,rgba(0,0,0,0.5) 100%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
        filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#00000000', endColorstr='#66000000',GradientType=1 ); /* IE6-9 */
        right:0;
    }
}