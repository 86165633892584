.lightbox-overlay{
    position: relative;
}
.lightbox-overlay a{
    position: relative;
    cursor: pointer;
}

.lightbox-overlay a:before{
    content: '';
    position: absolute;
    top:0;
    bottom:0;
    left:0;
    right:0;
    background: rgba(201,16,25,0.6);
    z-index: 1;
    opacity: 0;
    transition: opacity 300ms ease;
}

.lightbox-overlay a:hover:before,
.lightbox-overlay a:hover .lightbox-icon{
    opacity: 1;
}

.lightbox-icon{
    color: #fff;
    font-size: 125px;
    line-height: 0;
    z-index: 2;
    opacity: 0;
    transition: opacity 300ms ease;
}

.image-caption__wrapper {
    position: relative;
}

.image-caption{
    font-size: 12px;
    padding: 5px 15px;
    display: inline-block;
    position: absolute;
    bottom:0;
    left:0;
    z-index: 3;
}