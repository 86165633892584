
.form-control.form-control--transparent{
    background: transparent;
    border: 1px solid #fff;
    color: #fff;
}

select.form-control--transparent option{
    color: #000;
}

.form-control.form-control--grey{
    background: #d9d9d9;
    border: none;
    box-shadow: none;
}

.checkbox-styled input[type="checkbox"] {
    position: absolute;
    width: 1px;
    height: 1px;
    margin: -1px;
    padding: 0;
    overflow: hidden;
    clip: rect(0,0,0,0);
    border: 0;
}
.checkbox-styled__box {
    margin-right: 7px;
    overflow: hidden;
    vertical-align: middle;
    border: 1px solid #c4c6c7;
    margin-top: -2px;
    background: #ffffff;
    color: var(--color-primary);
    width: 19px;
    height: 19px;
    font-size: 21px;
    line-height: 19px;
}
.checkbox-styled__box:before {
    margin-left: -2px;
}
.checkbox-styled__box:after {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: #fff;
    transform-origin: right;
}
.checkbox-styled input[type="checkbox"]:checked + .checkbox-styled__box:after {
    transform: scaleX(0);
    transition: transform 150ms ease;
    /*text-indent: 0;*/
}
.checkbox-styled input[type="checkbox"]:focus ~ .checkbox-styled__box {
    border-color: var(--color-primary);
}
.has-error .checkbox-styled .checkbox-styled__box {
    border-color: var(--color-primary);
}
.checkbox-styled label {
    padding-left: 0;
    font-size: 15px;
    font-weight: 400;
    cursor: pointer;
    margin: 0;
    line-height: 1.2;
    display: block;
}

/*select*/
.select {
    position: relative;
}
.select>select {
    -moz-appearance: none;
    -webkit-appearance: none;
    appearance: none;
}
.select select::-ms-expand {
    display: none;
}
.select:after {
    display: inline-block;
    font-family: iconfont;
    speak: none;
    font-style: normal;
    font-weight: 400;
    text-rendering: auto;
    content: var(--icon-chevron);
    position: absolute;
    font-size: 30px;
    top: 55%;
    right: 12px;
    transform: translateY(-50%) rotate(90deg);
    color: var(--color-primary);
    pointer-events: none;
}

.select.select--transparent:after{
    color: #fff;
}

.select.select--grey:after{
    color: var(--color-default);
}

@media screen and (min-width: 768px){
    .select:after {
        right: 10px;
    }
}
select.input-xl {
    line-height:1.2;
}

@media screen and (min-width: 768px){
    .form-dealer-search .form-group{
        min-width: 157px;
        margin-right: 5px;
    }

    .form-dealer-search .form-control{
        width: 100%;
    }
}

.form-label {
    display: inline-block;
    max-width: 100%;
    margin-bottom: 5px;
    font-weight: bold;
    font-size: inherit;
    border: none;
}

/*parsley*/
.parsley-errors-list {
    color: #a94442;
    padding-left: 0;
    list-style: none;
}