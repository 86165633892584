.content-block{
    margin: 50px 0;
}

.content-block-small{
    margin: 25px 0;
}

.content-block__headline{
    margin-bottom: 25px;
    text-transform: uppercase;
}

.inline-languageswitch{
    position: relative;
    display: inline;
}

@media screen and (max-width: 767px) {
    .content-block{
        margin-top: 20px;
        margin-bottom: 20px;
    }

}

@media screen and (min-width: 768px){
    .content-block-small{
        margin: 50px 0;
    }
    .content-block{
        margin: 70px 0;
    }

    .content-block.content-block--60{
        margin: 40px 0;
    }

    .content-block__headline{
        margin-bottom: 35px;
    }
}

@media screen and (min-width: 992px){
    .content-block-small{
        margin: 70px 0;
    }

    .content-block.content-block--60{
        margin: 60px 0;
    }

    .content-block__headline{
        margin-bottom: 50px;
    }
}