.link-arrow{
    position: relative;
    display: inline-block;
    padding-left: 23px;
}

.link-arrow.link-arrow--right{
    padding-left: 0;
    padding-right: 25px;
}

.link-arrow.link-arrow--right.link-arrow--big{
    padding-right: 32px;
}

.link-arrow:before{
    font-family:iconfont;
    content: var(--icon-arrow);
    position: absolute;
    left: 0;
    top: 3px;
    font-size: 19px;
    transition: transform 100ms ease-out;
    display: inline-block;
    speak: none;
    font-style: normal;
    font-weight: 400;
    line-height: 1;
    text-rendering: auto;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    transform: translate(0,0);
    text-decoration: none;
}

@media screen and (max-width: 767px) {
    .link-arrow:before{
        top: 1px;
    }
}

.link-arrow.link-arrow--right:before{
    left: auto;
    right: 0;
}

.link-arrow.link-arrow--big:before{
    font-size: 25px;
    top:0;
}

.link-arrow:hover {
    /*text-decoration: underline;*/
}
.link-arrow:hover:before {
    transform: translateX(2px);
}

.link-arrow-rotate-90:before{
    transform: translate(0,0) rotate(90deg);
}

.link-arrow-rotate-90:hover:before{
    transform: translateX(0) translateY(2px) rotate(90deg);
}

@media screen and (max-width: 768px){
    .link-arrow.link-arrow--big:before{
        top:-2px;
    }
}