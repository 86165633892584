@media screen and (min-width: 768px) {

    .serie-detail {
        /*background: url(/static/build/img/bg/bg-serie-detail.jpg);*/
        /*background-repeat: no-repeat;*/
        /*background-size: 100%;*/
        /*background-position: top center;*/

        /* Permalink - use to edit and share this gradient: http://colorzilla.com/gradient-editor/#f6f6f6+0,f6f6f6+35,eaeaea+55,eaeaea+68,eaeaea+68,f6f6f6+85,f6f6f6+85,f6f6f6+100 */
        background: #f6f6f6; /* Old browsers */
        background: linear-gradient(to bottom, #f6f6f6 0%,#f6f6f6 35%,#eaeaea 55%,#eaeaea 68%,#eaeaea 68%,#f6f6f6 85%,#f6f6f6 85%,#f6f6f6 100%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */

    }
}


.serie-detail__heading {
    margin-top: 100px;
    margin-bottom: 35px;
    text-transform: uppercase;
}

.serie-detail__text {
    padding-right: 8%;
}

.serie-detail__img {
    position: relative;
}

/*tabs*/
@media screen and (min-width: 768px) {
    .serie-detail__tabs {
        position: absolute;
        top: 100px;
        right: 0;
        z-index: 1;
    }

    .serie-detail__img--mt{
        margin-top: 54px;
    }

}

@media screen and (max-width: 767px) {

    .serie-detail__heading {
        margin-top: 40px;
    }

    .serie-detail__tabs {
        padding-left: 0;
        list-style: none;
    }
    .serie-detail__tabs > li {
        display: inline-block;
        padding-left: 5px;
        padding-right: 5px;
    }

    .serie-detail__tabs > li {
        border: 1px solid var(--color-grey-border);
        border-left: none;
    }

    .serie-detail__tabs > li:first-child {
        border-left: 1px solid var(--color-grey-border);
    }

}

.serie-detail__tabs > li {
    text-align: center;
    background: white;
    border: 1px solid var(--color-grey-border);
    border-bottom: none;
}

.serie-detail__tabs > li:last-child {
    border-bottom: 1px solid var(--color-grey-border);
}

.serie-detail__tabs > li.active {
    background: var(--color-primary);
    color: white;
}

.serie-detail__tabs > li > a {
    position: relative;
    display: block;
    padding: 8px 15px;
}

.modal--3D-view {
    min-width: 65%;
}

.modal--3D-view .modal-content {
    border-radius: 0;
}

.close.close--modal {
    width: 60px;
    height: 60px;
    position: absolute;
    top: 0;
    right: 0;
    background: var(--color-primary);
    z-index: 1;
    color: white;
    opacity: 1;
    font-size: 28px;
}

@media screen and (max-width: 767px) {
    .close.close--modal {
        font-size: 24px;
        width: 40px;
        height: 40px;
    }

}

/* serie-detail is already very high (because of the background) -> next content-block should have only a small margin-top*/
.serie-detail+.content-block {
    margin-top: 10px;
}

.embed-responsive-serie-detail-inner {
    padding-top: 86%;
}

.serie-detail__image {
    position: absolute;
    top: 50%;
    left: 50%;
    width: 100%;
    transform: translate(-50%,-50%);
}