.pagination>li>span.skew-border{
    position: relative;
    padding: 9px 25px;
}

.skew-border:after,
.skew-border:before{
    content: '';
    position: absolute;
    width: 1px;
    top: 0;
    bottom: 0;
    background: #f6f6f6;
    z-index: 1;
    transform: skew(-25deg);
}

.skew-border:after {
    right: 0;
}

.skew-border:before {
    left: 0;
}

.pagination>li>a .icon{
    font-size: 20px;
    line-height: 0;
    position: relative;
    top: 2px;
}

.pagination>li>a.arrow-next .icon{
    top: 5px;
}

.pagination-bottom{
    text-align: center;
}

@media screen and (min-width: 768px){
    .pagination-bottom{
        text-align: right;
    }
}
@media screen and (max-width: 767px){
    .pagination{
        margin-bottom: 0;
    }
}