.contact-teaser {
    box-shadow: 0 3px 3px rgba(0, 0, 0, 0.1);
    border: 1px solid #f1f1f1;
    background: #fff;
    margin-bottom: 30px;
}
.contact-teaser__body {
    padding: 15px 30px;
    font-size: 16px;
}
.contact-teaser__body__address {
    margin-top: 25px;
}
.contact-teaser__body__address__block + .contact-teaser__body__address__block {
    margin-top: 8px;
}

.contact-teaser__title {
    color: #c91019;
    text-transform: uppercase;
}
.contact-prefix {
    width: 20px;
    display: inline-block;
}

.embed-responsive-5by6 {
    padding-top: 120%;
}

@media screen and (max-width: 767px) {
    .contact-teaser__body.location {
        padding-left: 25px;
    }
}