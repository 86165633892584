.img-teaser{
    position: relative;
}

.img-teaser__body{
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    top: 0;
    background: linear-gradient(to bottom,  rgba(0,0,0,0) 0%,rgba(0,0,0,0) 41%,rgba(0,0,0,0.5) 100%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
}

.img-teaser__link{
    position: absolute;
    bottom: 0;
    left:0;
    right: 0;
    line-height: 1.2;
    padding: 0 20px 10px 35px;
}

.img-teaser__link:before{
    content: var(--icon-chevron);
    font-family: iconfont;
    position: absolute;
    left: 5px;
    top: -2px;
    font-size: 26px;
}

.img-teaser .img-teaser__img img{
    transition: transform 250ms ease;
    backface-visibility: hidden;
}
.img-teaser:hover .img-teaser__img img{
    transform: scale(1.03) translateZ(0);
}

@media screen and (min-width: 1200px){
    .img-teaser__link{
        line-height: 42px;
        padding: 0 20px 10px 65px;
        font-size: 35px;

    }

    .img-teaser__link:before{
        font-size: 45px;
        left: 20px;
        top:auto;
    }
}

@media screen and (min-width: 768px){
    .img-teaser__link{
        line-height: 30px;
        padding: 0 20px 10px 45px;
        font-size: 25px;
    }

    .img-teaser__link:before {
        font-size: 35px;
        top: 2px;
        left:10px;
    }

}

@media screen and (max-width: 767px){
    .img-teaser__link{
        font-size: 20px;
    }
}

.is-video .img-teaser__link{
    padding-left: 35px;
}

.is-video .img-teaser__link:before{
    display: none;
}

.img-teaser .video-lightbox{
    position: absolute;
    left:0;
    right: 0;
    top:0;
    bottom:0;
}
