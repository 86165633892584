.img-text-block{
    padding-bottom: 30px;
    border-bottom: 1px solid #c6c4c4;
}

.img-text-block__heading{
    margin-bottom: 25px;
}

.img-text-block__subtitle{
    font-size: 20px;
    color: #999;
    font-family: var(--font-trade-gothic-regular);
}

@media screen and (min-width: 768px){

    .img-text-block{
        padding-bottom: 60px;
    }

    .img-text-block__heading{
        margin-bottom: 50px;
    }
    .img-text-block__subtitle{
        font-size: 20px;
    }

    .img-text-slider__item .btn{
        margin-top: 30px;
    }
}

.img-text-slider__img{
    position: relative;
}

.img-text-slider__item .btn{
    margin-top: 15px;
}

.wysiwyg .top-headline {
    font-size: 28px;
    font-weight: 700;
}