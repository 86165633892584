.modal.modal--lang .modal-dialog{
    margin: 200px auto;
}

.modal--lang .modal-body{
    padding: 30px 40px;
}

.navbar-lang .modal-body{
    font-size: 16px;
    line-height: 26px;
}

@media screen and (max-width: 767px){
    .modal--lang .modal-body a.btn{
        margin-bottom: 15px;
        display: block;
        float: none!important;
    }

    .modal--lang .modal-body{
        padding: 30px;
    }
}