.footer__top {
    padding: 40px 0;
    background-color: #c1111a;
    background-image: url(/static/debug/img/bg/footer-bg.png);
    background-size: cover;
    background-repeat: no-repeat;
    background-position: bottom center;
    color: #fff;
}

.footer__bottom {
    padding: 15px 0;
    background-color: #1b1b1b;
    color: #fff;
}

.footer__bottom a:hover {
    color: var(--color-primary);
}

.footer__link-block {
    color: #fff;
}

.footer__link-block+.footer__link-block {
    margin-top: 35px;
}

.footer__link-block-title {
    text-transform: uppercase;
    padding-bottom: 10px;
    font-size: 16px;
    font-weight: bold;
}

.footer__link-block-list {
    font-size: 16px;
}

.footer__social-icon-list {
    margin-bottom: 0;
}

.footer__social-icon {
    font-size: 24px;
}

@media screen and (max-width: 767px) {
    .footer__bottom{
        padding: 40px 0;
    }

    .footer__social-icon {
        font-size: 40px;
    }
}

.footer__link-list {
    margin-bottom: 0;
}

.footer__link-list>li{
    padding-left: 20px;
    padding-right: 20px;
}

.footer {
    position: relative;
}

.affix-scroll-top-button {
    position: fixed;
    bottom: 0;
    right: 0;
    transition: opacity .2s ease-in-out;
    opacity: 0;
    z-index: 1000;
}

.affix-scroll-top-button.affix{
    opacity: 1;
}

.affix-scroll-top-button.affix-bottom {
    opacity: 1;
    position: absolute;
    bottom: 100%;
    top: auto !important; /*overwrite inline style*/
}


@media (min-width: 1600px) {
    .affix-scroll-top-button {
        right: 50%;
        margin-right: -800px;
    }
}

.scroll-top-btn {
    width: 50px;
    height: 50px;
    padding: 5px;
}