.timeline {
    position: relative;
}

.timeline:before {
    top: 30px;
    bottom: 0;
    position: absolute;
    content: " ";
    width: 3px;
    background-color: black;
    left: 50%;
    margin-left: -2px;
}

.timeline > li {
    margin-bottom: 20px;
    position: relative;
}

.timeline > li:before,
.timeline > li:after {
    content: " ";
    display: table;
}

.timeline > li:after {
    clear: both;
}

.timeline > li:before,
.timeline > li:after {
    content: " ";
    display: table;
}

.timeline > li:after {
    clear: both;
}

.timeline .timeline-item {
    width: 50%;
    float: left;
    padding: 20px;
    padding-left: 0;
    padding-right: 70px;
    position: relative;
    text-align: right;
}

.timeline .timeline-item.timeline-item--right {
    text-align: left;
    float: right;
    padding-left: 70px;
    padding-right: 0px;
}

.timeline .timeline-item:before {
    content: " ";
    height: 1px;
    width: 40px;
    background: black;
    position: absolute;
    right: 0;
    top: 30px;
}

.timeline .timeline-item.timeline-item--right:before {
    left: 0;
    right: auto;
}

.timeline-title {
    margin-top: 0;
    color: inherit;
}

@media (max-width: 767px) {
    .timeline:before {
        left: 0;
        margin-left: 0;
    }

    .timeline .timeline-item {
        width: 100%;
        float: right;
        text-align: left;
        padding-left: 40px;
        padding-right: 0px;
    }

    .timeline .timeline-item:before {
        left: 0;
        right: auto;
        width: 30px;
    }

    .timeline .timeline-item.timeline-item--right {
        padding-left: 40px;
    }

    .timeline-item__image {
        margin-bottom: 10px;
    }
}