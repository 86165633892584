.news-teaser{
    border: 1px solid var(--color-grey-border);
}

.news-teaser__body{
    padding: 20px;
}

@media screen and (min-width: 1200px){
    .news-teaser__body{
        padding: 30px;
    }

}

.news-teaser .news-teaser__img img{
    transition: transform 250ms ease;
    backface-visibility: hidden;

}
.news-teaser:hover .news-teaser__img img{
    transform: scale(1.03) translateZ(0);
}


.news-teaser .teaser-more-arrow{
    transition: all 150ms ease;
    position: relative;
    right: 0;
}

.news-teaser:hover .teaser-more-arrow{
    right: -3px;
}