.page-heading__hl-block{
    margin-bottom: 25px;
    margin-top: 30px;
}

.page-heading__teaser{
    margin-top: 30px;
}

.page-heading__date {
    font-size: 25px;
    color: #999999;
}

@media screen and (min-width: 992px){
    .page-heading__hl-block{
        margin-bottom: 40px;
        margin-top: 70px;
    }
}

@media screen and (min-width: 768px){
    .page-heading__hl-block{
        margin-bottom: 30px;
        margin-top: 45px;
    }

    .page-heading__teaser{
        margin-top: 70px;
    }
}