.event-teaser {
    margin-bottom: 20px;
}

.event-teaser__img {
    background: white;
}

.event-teaser__img__inner {
    padding: 30px;
}

.event-teaser.event-teaser--highlighted .event-teaser__img__inner {
    width: 50%;
}

.event-teaser.event-teaser--highlighted {
    border: 1px solid var(--color-primary);
    background: var(--color-primary);
}

.event-teaser .event-teaser__body {
    padding-top: 15px;
}

.event-teaser.event-teaser--highlighted .event-teaser__body {
    color: white;
    padding: 15px 20px;
}

.event-teaser.event-teaser--highlighted .text-primary {
    color: white;
}
