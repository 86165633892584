.table-relative{
    position: relative;
}

.custom-table{
    width: 100%;
    margin: 20px 0 40px;
}

@media screen and (min-width: 768px){
    .table-relative{
        position: relative;
        padding-left: 335px;
    }


    .custom-table{
        width: auto;
    }

    .table .custom-table__body th{
        position: absolute;
        width: 360px;
        left: 0;
        top: auto;
    }
}


.table .custom-table__head>tr>th{
    background: var(--color-primary);
    border-bottom:none;
    color: #fff;
}

.table .custom-table__head>tr>th:first-child{
    background: transparent;
    border-left:none;
    width: 27%;

}

.table .custom-table__head th{
    text-align: center;
    border-left: 2px solid #fff;
    padding: 15px 14px;
    font-weight: 700;
    border-top: none;
}

.table .custom-table__td-important>td{
    color: var(--color-primary);
    font-weight: 700;
}

.table .custom-table__body td{
    text-align: center;
    padding: 18px 10px;
    border-top: none;
    border-left: 1px solid #e3e3e3;
}

.table .custom-table__body th{
    padding: 18px 15px 18px 20px;
    font-weight: 700;
    border-top: none;

}

.table-striped>tbody>tr:nth-of-type(odd) {
    background-color: #efefef;
}

.table-striped>tbody>tr:nth-of-type(odd)>th{
     background-color: #efefef;
 }

.table-striped>tbody>tr {
    background-color: #fff;
}

.table-striped>tbody>tr>th {
    background-color: #fff;
}

.table .custom-table__body tr td:nth-child(3n-1) {
    border-left: 2px solid #fff;
}

.table.table-no-head .custom-table__head>tr>th:first-child{
    width: 27%;

}
.table.table-no-head .custom-table__body th{
    width: 27%;
}

@media screen and (min-width: 768px){
    .table .custom-table__head th{
        font-size: 20px;
    }
}

@media screen and (max-width: 767px){
    .table-responsive>.table>thead>tr>th,
    .table-responsive>.table>tbody>tr>th,
    .table-responsive>.table>tfoot>tr>th,
    .table-responsive>.table>thead>tr>td,
    .table-responsive>.table>tbody>tr>td,
    .table-responsive>.table>tfoot>tr>td{
        white-space: normal;
    }
}

.custom-table a {
    color: #c91019;
}